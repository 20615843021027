import { ChangeDetectionStrategy, Component, input, Signal } from "@angular/core";
import { AreaResponsibilityInfo, ImpersonationEndpoint, MainMenuModel } from "apina-frontend";
import { map } from "rxjs/operators";
import { combineLatest, firstValueFrom } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { SessionContext } from "../session/session-context.service";
import { DialogsService, ErrorService, HelsinkiDatePipe, LocalDate, SimplifyUsernamePipe, storeSessionToken, ZoneId } from "common";
import { PilotageService } from "../pilotage/pilotage.service";
import { MainMenuService } from "./main-menu.service";
import { FeedbackComponent } from "../feedback/feedback.component";
import { parseUsername } from "../domain/id-parsing";
import { FrontendEnvironmentService } from "../config/frontend-environment.service";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { FrontendTranslations } from "../l10n/frontend.translations";
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MainMenuLinkComponent } from "./main-menu-link/main-menu-link.component";
import { NgClass } from "@angular/common";
import { MainMenuAreaResponsibilitiesComponent } from "./main-menu-area-responsibilities/main-menu-area-responsibilities.component";

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HelsinkiDatePipe,
        MatProgressBarModule,
        MatTooltipModule,
        RouterLink,
        SimplifyUsernamePipe,
        TranslateModule,
        MainMenuLinkComponent,
        NgClass,
        MainMenuAreaResponsibilitiesComponent,
    ],
})
export class MainMenuComponent {

    section = input<string | null>(null);

    readonly data: Signal<MenuData | undefined>;

    constructor(
        frontendTranslations: FrontendTranslations,
        pageContextService: SessionContext,
        pilotageService: PilotageService,
        private readonly frontendEnvironmentService: FrontendEnvironmentService,
        private readonly dialogsService: DialogsService,
        mainMenuService: MainMenuService,
        private readonly impersonationEndpoint: ImpersonationEndpoint,
        private readonly errorService: ErrorService,
        private readonly matDialog: MatDialog,
    ) {

        this.data = toSignal(combineLatest([
            toObservable(this.section),
            toObservable(mainMenuService.latestData),
            toObservable(pageContextService.currentZone),
            frontendTranslations.stream(mainMenuTranslationKeys)
        ]).pipe(map(([section, model, zoneId, translations]) => {
            if (model == null) return undefined;

            const menu = buildMenu(model, section, translations, pilotageService, zoneId);
            return {
                version: model.version,
                username: model.username,
                links: menu.links,
                selection: menu.selection,
                mayEditAreaResponsibilities: model.mayEditAreaResponsibilities,
                areaResponsibilities: model.areaResponsibilities,
                mayImpersonate: model.mayImpersonate,
                impersonating: model.impersonating,
                mayViewAdminView: model.mayViewAdminView,
                medicalCertificateExpirationDate: model.medicalCertificateExpirationDate,
            } satisfies MenuData;
        })));
    }

    get menuBackgroundClass(): string {
        return this.frontendEnvironmentService.isFinnpilotQaEnvironment() ? "bg-main-menu-background-qa" : "bg-blue";
    }

    openFeedback(): void {
        this.matDialog.open(FeedbackComponent);
    }

    showImpersonateDialog(event: Event): void {
        event.preventDefault();

        this.dialogsService.showInputDialog({label: "Anna käyttäjätunnus impersonointia varten", confirmText: "OK"}, async (result) => {
            if (result) {
                const login = parseUsername(result);
                const response = await firstValueFrom(this.impersonationEndpoint.impersonate({login}));
                storeSessionToken(response.jwtToken);
                window.location.reload();
            }
        });
    }

    endImpersonation(event: Event): void {
        event.preventDefault();

        this.impersonationEndpoint.endImpersonation().subscribe({
            next: result => {
                storeSessionToken(result.jwtToken);
                window.location.reload();
            },
            error: e => this.errorService.showUpdateError(e)
        });
    }
}

interface MenuData {
    version: string | null;
    username: string;
    links: NavLink[];
    selection: NavSelection | null;
    mayViewAdminView: boolean;
    mayImpersonate: boolean;
    impersonating: boolean;
    mayEditAreaResponsibilities: boolean;
    areaResponsibilities: ReadonlyArray<AreaResponsibilityInfo>;
    medicalCertificateExpirationDate: LocalDate | null;
}

interface NavLinkInput {
    url?: string;

    onClick?(): void;

    title: string;
    sectionId: string;
    visible: boolean;
    target?: string;
    children?: NavLinkInput[];
    modalParams?: string;
    routerLink?: string | unknown[];
    routerLinkQueryParams?: { [k: string]: unknown };
}

export interface NavLink {
    url?: string;

    onClick?(): void;

    title: string;
    visible: boolean;
    target?: string;
    selected: boolean;
    children: NavLink[];
    modalParams?: string;
    routerLink?: string | unknown[];
    routerLinkQueryParams?: { [k: string]: unknown };
}

type MainMenuTranslations = Record<MainMenuTranslationKey, string>;

const mainMenuTranslationKeys = [
    "main-menu.info-pages",
    "main-menu.traffic-log",
    "main-menu.new-pilotage",
    "main-menu.standard-notices",
    "main-menu.personnel",
    "main-menu.operators",
    "main-menu.vacations",
    "main-menu.billing",
    "main-menu.invoices",
    "main-menu.reports",
    "main-menu.customers",
    "main-menu.company-changes",
    "main-menu.vessels",
    "main-menu.routes",
    "main-menu.route-chains",
    "main-menu.route-endpoints",
    "main-menu.port-hubs",
    "main-menu.pilot-stations",
    "main-menu.timesheet",
    "main-menu.timesheet-superior-overview",
    "main-menu.shifts",
    "main-menu.taskboard",
] as const;

type MainMenuTranslationKey = typeof mainMenuTranslationKeys extends readonly (infer U)[] ? U : never;

function buildMenu(
    model: MainMenuModel,
    section: string | null,
    translations: MainMenuTranslations,
    pilotageService: PilotageService,
    zoneId: ZoneId | null): { links: NavLink[]; selection: NavSelection | null } {

    const mainSection = section ? section.split("/")[0] : null;

    const input: NavLinkInput[] = [
        {
            routerLink: "/info",
            routerLinkQueryParams: zoneParams(zoneId),
            title: translations["main-menu.info-pages"],
            sectionId: 'info',
            visible: true
        },
        {
            routerLink: "/traffic-log",
            routerLinkQueryParams: zoneParams(zoneId),
            title: translations["main-menu.traffic-log"],
            sectionId: 'traffic-log',
            visible: true,
            children: [
                {
                    onClick(): void {
                        pilotageService.openCreateNewPilotageDialog();
                    },
                    title: translations["main-menu.new-pilotage"],
                    sectionId: 'traffic-log',
                    visible: model.mayCreateNewPilotageOrder
                },
                {
                    url: "https://app.portnet.fi/",
                    title: 'PortNet',
                    sectionId: 'traffic-log',
                    visible: model.mayViewExternalLinks,
                    target: "_blank"
                },
                {
                    url: "https://maritime.ihs.com/",
                    title: 'Lloyds Seaweb',
                    sectionId: 'traffic-log',
                    visible: model.mayViewExternalLinks,
                    target: "_blank"
                },
                {
                    url: "/pilotage/standard-notices",
                    title: translations["main-menu.standard-notices"],
                    sectionId: 'traffic-log',
                    visible: model.mayListStandardNotices,
                    modalParams: "menubar=false,scrollbars,height=750,width=770,resizable"
                },
                {
                    routerLink: "/portnet",
                    title: 'Portnet-jono',
                    sectionId: 'traffic-log',
                    visible: model.mayImportPortnetChanges,
                },
                {
                    routerLink: "/scheduling",
                    routerLinkQueryParams: zoneParams(zoneId, "pilotage.area", "pilotage.pilotage-area", "pilotage.pilot-station"),
                    title: "Suunnittelunäkymä",
                    sectionId: 'traffic-log',
                    visible: model.mayViewScheduling
                },
                {
                    routerLink: "/map",
                    title: "Kartta",
                    sectionId: 'traffic-log',
                    visible: true
                }, {
                    routerLink: "/weather",
                    title: "Sää",
                    sectionId: 'traffic-log',
                    visible: true
                },
            ],
        },
        {
            routerLink: '/pilots',
            routerLinkQueryParams: {...zoneParams(zoneId), bars: "", notices: ""},
            title: translations["main-menu.personnel"],
            sectionId: 'personnel',
            visible: model.mayViewPilots,
            children: [
                {
                    routerLink: '/operators',
                    routerLinkQueryParams: {...zoneParams(zoneId)},
                    title: translations["main-menu.operators"],
                    sectionId: "personnel",
                    visible: model.mayListOperators
                },
                {
                    routerLink: '/operators/timeline',
                    routerLinkQueryParams: zoneParams(zoneId),
                    title: "Kutterinhoitajien työtehtävät", // TODO: localize and pick a good title
                    sectionId: "personnel",
                    visible: model.mayViewOperatorTimeline
                },
            ]
        },
        {
            routerLink: "/billing/pilotages",
            routerLinkQueryParams: zoneParams(zoneId),
            title: translations["main-menu.billing"],
            sectionId: 'billing',
            visible: model.mayViewBills,
            children: [
                {
                    routerLink: "/billing/invoices",
                    routerLinkQueryParams: zoneParams(zoneId),
                    title: translations["main-menu.invoices"],
                    sectionId: "billing",
                    visible: model.mayViewBills
                }
            ]
        },
        {
            routerLink: "/reports",
            title: translations["main-menu.reports"],
            sectionId: 'reports',
            visible: model.mayViewReportListing
        },
        {
            routerLink: "/companies",
            title: translations["main-menu.customers"],
            sectionId: 'companies',
            visible: model.mayViewCompanies,
            children: [
                {
                    routerLink: "/companies/changes",
                    title: translations["main-menu.company-changes"],
                    sectionId: "companies",
                    visible: model.mayViewCompanyChanges
                },
            ]
        },
        {
            routerLink: "/vessels",
            title: translations["main-menu.vessels"],
            sectionId: 'vessels',
            visible: model.mayListVessels
        },
        {
            routerLink: "/routes",
            title: translations["main-menu.routes"],
            sectionId: 'routes',
            visible: model.mayViewRouteRegistry,
            children: [
                {
                    routerLink: "/routes/chains",
                    title: translations["main-menu.route-chains"],
                    sectionId: 'routes',
                    visible: model.mayViewRouteChains
                },
                {
                    routerLink: "/routes/endpoints",
                    title: translations["main-menu.route-endpoints"],
                    sectionId: 'routes',
                    visible: model.mayViewRouteEndpoints
                },
                {
                    routerLink: "/routes/port-hubs",
                    title: translations["main-menu.port-hubs"],
                    sectionId: 'routes',
                    visible: model.mayViewRouteEndpoints
                },
            ]
        },
        {
            routerLink: "/stations",
            title: translations["main-menu.pilot-stations"],
            sectionId: 'stations',
            visible: model.mayViewStations,
        },
        {
            routerLink: "/timesheet/pilots/superior-overview",
            routerLinkQueryParams: zoneParams(zoneId),
            title: translations["main-menu.timesheet"],
            sectionId: 'timesheet',
            visible: model.employeeType === null && model.mayViewTimesheetSuperiorOverview
        },
        {
            url: "/timesheet/overview",
            title: translations["main-menu.timesheet"],
            sectionId: 'timesheet',
            visible: model.employeeType != null,
            children: [
                {
                    routerLink: section === "timesheet/operators" ? "/timesheet/operators/superior-overview" : "/timesheet/pilots/superior-overview",
                    routerLinkQueryParams: zoneParams(zoneId),
                    title: translations["main-menu.timesheet-superior-overview"],
                    sectionId: 'timesheet',
                    visible: model.mayViewTimesheetSuperiorOverview
                }
            ]
        },
        {
            routerLink: "/shifts",
            title: translations["main-menu.shifts"],
            routerLinkQueryParams: zoneParams(zoneId),
            sectionId: 'shifts',
            visible: model.mayViewShifts,
            children: [
                {
                    routerLink: "/shifts/secondments",
                    title: "Komennusmatkat",
                    sectionId: "shifts",
                    visible: model.mayPlanShifts,
                },
                {
                    routerLink: '/holidays',
                    title: "Pyhäpäivät",
                    sectionId: "shifts",
                    visible: model.mayManageHolidays
                },
                {
                    routerLink: "/shifts/vacations",
                    title: translations["main-menu.vacations"],
                    sectionId: "shifts",
                    visible: model.mayViewVacations
                },
            ]
        },
        {
            routerLink: "/pec",
            title: "PEC",
            sectionId: "pec",
            visible: model.mayViewPecs,
            children: [
                {
                    routerLink: "/pec/fairways",
                    title: "PEC-väylät",
                    sectionId: "pec",
                    visible: model.mayViewPecs,
                }
            ]
        },
        {
            url: "/taskboard",
            title: translations["main-menu.taskboard"],
            sectionId: 'taskboard',
            visible: model.mayViewTaskboard,
            modalParams: "menubar=false,scrollbars,height=708,width=770,top=0,resizable"
        },
        {
            url: model.loginAutomaticallyToPilotOnlineUrl ?? '',
            title: "Pilot Online",
            sectionId: "pilot-online",
            visible: model.loginAutomaticallyToPilotOnlineUrl != null
        },
        {
            routerLink: "/mpilot/install",
            title: "mPilot",
            sectionId: "mpilot",
            visible: model.mayViewMPilotPages
        },
    ];

    const links = input.map(it => createNavLink(it, mainSection)).filter(it => it.visible);

    return {
        links: links,
        selection: getSelectionWithSublinks(links)
    };
}

function createNavLink(p: NavLinkInput, sectionId: string | null): NavLink {
    const children = (p.children || []).filter(c => c.visible).map(c => ({...c, selected: c.sectionId === sectionId, children: []}));
    const selected = p.sectionId === sectionId || children.some(c => c.selected);

    return {...p, selected, children};
}

function zoneParams(zoneId: ZoneId | null, areaParam = "areaId", pilotageAreaParam = "pilotageAreaId", pilotStationParam = "pilotStationId"): { [k: string]: unknown } {
    if (zoneId == null)
        return {};

    let param: string;
    switch (zoneId.type) {
        case "area":
            param = areaParam;
            break;
        case "pilotage-area":
            param = pilotageAreaParam;
            break;
        case "pilot-station":
            param = pilotStationParam;
            break;
    }

    return {[param]: zoneId.id};
}

function getSelectionWithSublinks(topLevelLinks: NavLink[]): NavSelection | null {
    for (let i = 0; i < topLevelLinks.length; ++i) {
        const link = topLevelLinks[i];
        if (link.selected) return ({
            topLevelIndex: i,
            sublinks: link.children
        });
    }

    return null;
}

interface NavSelection {
    topLevelIndex: number;
    sublinks: NavLink[];
}
