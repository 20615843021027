@if (params.responsibility.responsibleLogin; as responsible) {
    <h2 mat-dialog-title>Vastuuhenkilö: {{ responsible | simplifyUsername }}</h2>
} @else {
    <h2 mat-dialog-title>Alueet ilman vastuuhenkilöä</h2>
}

<div mat-dialog-content>
    <div class="flex flex-col gap-2">
        @for (area of areas; track area.id) {
            <mat-checkbox [formControl]="area.formControl">{{ area.name }}</mat-checkbox>
        }
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">Peruuta</button>

    @if (params.responsibility.assignedToCurrentUser) {
        <app-spinner-button  (spinnerClick)="releaseResponsibility()" [active]="saving()" [disabled]="disabled()">Luovu vastuusta</app-spinner-button>
    } @else {
        <app-spinner-button  (spinnerClick)="takeResponsibility()" [active]="saving()" [disabled]="disabled()">Ota vastuu</app-spinner-button>
    }
</div>
