@if (data(); as data) {
    <div class="no-print text-xs">
        <nav class="nav-wrapper overflow-x-auto text-white m-0 py-1 px-4 font-sans" [ngClass]="menuBackgroundClass">
            <div class="flex flex-row gap-2 nav-top">
                <div class="flex flex-row gap-10 justify-between">
                    <h1 class="!text-xs !m-0 uppercase">Pilotweb</h1>
                    @if (data.version; as version) {
                        <span>{{ version }}</span>
                    }
                </div>
                <div class="text-right flex-grow space-x-2">
                    <a routerLink="/account/settings" class="!text-main-menu-nav-link">{{ data.username }}</a>
                    @if (data.mayImpersonate) {
                        <a href="" (click)="showImpersonateDialog($event)" class="!text-main-menu-nav-link">Vaihda tunnus</a>
                    }
                    @if (data.impersonating) {
                        <a href="" (click)="endImpersonation($event)" class="!text-main-menu-nav-link">Lopeta impersonointi</a>
                    }
                    @if (data.mayViewAdminView) {
                        <a routerLink="/admin" class="!text-main-menu-nav-link">Admin</a>
                    }
                    <a href="" class="!text-main-menu-nav-link" (click)="$event.preventDefault(); openFeedback()">Palaute</a>
                </div>
            </div>

            <table class="border-none border-spacing-0">
                <tr>
                    @for (link of data.links; track $index) {
                        <td class="pr-1.5">
                            <app-main-menu-link [link]="link"/>
                        </td>
                    }
                </tr>
                @if (data.selection; as selection) {
                    <tr>
                        @if (selection.topLevelIndex > 0) {
                            <td [colSpan]="selection.topLevelIndex"></td>
                        }
                        <td [colSpan]="data.links.length - selection.topLevelIndex">
                            @for (link of selection.sublinks; track $index) {
                                <app-main-menu-link [link]="link" class="mr-1.5"/>
                            }
                        </td>
                    </tr>
                }
            </table>

            @if (data.medicalCertificateExpirationDate; as expirationDate) {
                <div class="medical-certificate-expires">
                    {{ 'main-menu.your-medical-certificate-is-expiring' | translate: {date: expirationDate | helsinkiDate:"date-with-year"} }}
                </div>
            }
        </nav>
        <app-main-menu-area-responsibilities [responsibilities]="data.areaResponsibilities" [mayEdit]="data.mayEditAreaResponsibilities"/>
    </div>
} @else {
    <mat-progress-bar mode="indeterminate"/>
}
