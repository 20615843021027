import { registerCommonApinaSerializers } from "common";
import { ApinaConfig, provideApina } from "apina-frontend";
import { parseVesselLocationEvent, parseVesselMetadataEvent } from "maps";
import { Provider } from "@angular/core";

export function provideFrontendApina(): Provider[] {
    return provideApina({
        config: createApinaConfig(),
    });
}

function createApinaConfig(): ApinaConfig {
    const config = new ApinaConfig();

    registerCommonApinaSerializers(config);

    config.registerSerializer("VesselLocationEvent", {
        deserialize: e => (e != null) ? parseVesselLocationEvent(e) : null,
        serialize() {
            throw Error("serialization of VesselLocationEvent is not supported");
        }
    });

    config.registerSerializer("VesselMetadataEvent", {
        deserialize: e => (e != null) ? parseVesselMetadataEvent(e) : null,
        serialize() {
            throw Error("serialization of VesselLocationEvent is not supported");
        }
    });

    return config;
}
