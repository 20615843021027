import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AreaResponsibilityInfo } from "apina-frontend";
import { SimplifyUsernamePipe } from "common";
import { NgClass } from "@angular/common";
import { MatTooltip } from "@angular/material/tooltip";
import { AreaResponsibilityComponent, AreaResponsibilityComponentParams } from "../../dispatchers/area-responsibilities/area-responsibility.component";
import { MatDialog } from "@angular/material/dialog";
import { MainMenuService } from "../main-menu.service";

@Component({
  selector: 'app-main-menu-area-responsibilities',
  standalone: true,
    imports: [
        SimplifyUsernamePipe,
        NgClass,
        MatTooltip
    ],
  templateUrl: './main-menu-area-responsibilities.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuAreaResponsibilitiesComponent {

    @Input({required: true}) responsibilities!: ReadonlyArray<AreaResponsibilityInfo>;
    @Input() mayEdit = false;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly mainMenuService: MainMenuService,
    ) {
    }

    changeResponsible(event: Event, responsibility: AreaResponsibilityInfo): void {
        event.preventDefault();
        if (!this.mayEdit) return;

        this.matDialog.open<AreaResponsibilityComponent, AreaResponsibilityComponentParams>(AreaResponsibilityComponent, {
            data: {
                responsibility: responsibility
            }
        }).afterClosed().subscribe(result => {
            if (result)
                this.mainMenuService.reload();
        });
    }
}
