import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavLink } from "../main-menu.component";
import { RouterLink } from "@angular/router";
import { NgClass } from "@angular/common";

@Component({
    selector: 'app-main-menu-link',
    standalone: true,
    imports: [
        RouterLink,
        NgClass,
    ],
    templateUrl: './main-menu-link.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuLinkComponent {

    @Input({required: true}) link!: NavLink;

    get textClass(): string {
        return this.link.selected ? "!text-main-menu-nav-link-current" : "!text-main-menu-nav-link";
    }

    navigateTo(event: Event, link: NavLink): void {
        if (link.onClick) {
            event.preventDefault();
            link.onClick();
            return;
        }
        const modalParams = link.modalParams;
        if (modalParams) {
            event.preventDefault();
            window.open(link.url, "", modalParams);
        }
    }
}
