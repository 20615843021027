import { Routes } from "@angular/router";
import { OAuthComponent, oauthLoginGuard } from "common";
import { LogoutComponent } from "./oauth/logout/logout.component";

export const FRONTEND_ROUTES: Routes = [
    // routes that do not need login to work
    {
        path: 'oauth',
        component: OAuthComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },

    // all routes that need users to be logged in
    {
        path: '',
        canActivate: [oauthLoginGuard("/oauth")],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/traffic-log',
            },
            {
                path: 'account',
                loadChildren: () => import('./account/account.routes'),
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.routes'),
            },
            {
                path: 'billing',
                loadChildren: () => import('./billing/billing.routes'),
            },
            {
                path: 'changelog',
                loadChildren: () => import('./changelog/changelog.routes'),
            },
            {
                path: 'comments',
                loadChildren: () => import('./comments/comments.routes'),
            },
            {
                path: 'companies',
                loadChildren: () => import('./companies/companies.routes'),
            },
            {
                path: 'holidays',
                loadChildren: () => import('./holidays/holidays.routes'),
            },
            {
                path: 'info',
                loadChildren: () => import('./info/info.routes'),
            },
            {
                path: 'map',
                loadChildren: () => import('./map/map.routes')
            },
            {
                path: 'mpilot',
                loadChildren: () => import('./mpilot/mpilot.routes'),
            },
            {
                path: 'operators',
                loadChildren: () => import('./operators/operators.routes'),
            },
            {
                path: 'pec',
                loadChildren: () => import('./pec/pec.routes'),
            },
            {
                path: 'portnet',
                loadChildren: () => import('./portnet/portnet.routes'),
            },
            {
                path: 'pilotage',
                loadChildren: () => import('./pilotage/pilotage.routes'),
            },
            {
                path: 'pilots',
                loadChildren: () => import('./pilots/pilots.routes'),
            },
            {
                path: 'pilot-timesheet',
                loadChildren: () => import('./timesheet-pilot/pilot-timesheet.routes'),
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.routes'),
            },
            {
                path: 'rest',
                loadChildren: () => import('./rest/rest.routes'),
            },
            {
                path: 'routes',
                loadChildren: () => import('./routes/routes.routes'),
            },
            {
                path: 'scheduling',
                loadChildren: () => import('./scheduling/scheduling.routes'),
            },
            {
                path: 'shifts',
                loadChildren: () => import('./shifts/shifts.routes'),
            },
            {
                path: 'stations',
                loadChildren: () => import('./stations/stations.routes'),
            },
            {
                path: 'taskboard',
                loadChildren: () => import('./taskboard/taskboard.routes'),
            },
            {
                path: 'timesheet',
                loadChildren: () => import('./timesheet/timesheet.routes'),
            },
            {
                path: 'traffic-log',
                loadChildren: () => import('./traffic-log/traffic-log.routes'),
            },
            {
                path: 'vessels',
                loadChildren: () => import('./vessels/vessels.routes'),
            },
            {
                path: 'weather',
                loadChildren: () => import('./weather/weather.routes')
            },
        ]
    },
];
