<h2 mat-dialog-title>Palaute</h2>

<mat-dialog-content>
    <p>
        Onko sinulla ongelmia Pilotwebin kanssa tai kehitysehdotuksia? Lähetä palautetta ylläpidolle.
    </p>

    <textarea title="Palaute" name="message" [formControl]="form.controls.message" class="w-full" rows="10" required></textarea>

    <div style="margin-top: 0.5rem">
        @if (error()) {
            <p class="error">
                Palautteen lähettämien epäonnistui.
                Yritä uudestaan tai lähetä palaute sähköpostilla osoitteeseen
                <a href="mailto:finnpilot-yllapito@evident.fi">finnpilot-yllapito&#64;evident.fi</a>.
            </p>
        }

    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="form.invalid || saving()" (click)="send()"> {{ saving() ? "Lähetetään..." : "Lähetä" }}</button>
</mat-dialog-actions>
