<div class="bg-white text-right py-1 px-4 space-x-3">
    @for (resp of responsibilities; track $index) {
        <span [ngClass]="mayEdit ? 'cursor-pointer hover:underline' : null" (click)="changeResponsible($event, resp)">
            @if (resp.responsibleLogin; as login) {
                <span class="text-black">{{ login | simplifyUsername }}:</span>
            } @else {
                <span class="text-error">ei vastuullista:</span>
            }

            @for (area of resp.areas; track $index; let last = $last) {
                <span class="area text-gray-500" [class.!text-error]="area.hasCriticalTasks || resp.responsibleLogin == null" [matTooltip]="area.hasCriticalTasks ? 'Alueella on kriittisiä tehtäviä' : ''">
                    {{ area.name }}@if (!last) {
                    <span>, </span>
                }</span>
            }
        </span>
    }
</div>
