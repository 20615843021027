<!-- The component is not exposed to users, so it's quite barebones -->

<mat-card class="max-w-md my-8 mx-auto">
    <mat-card-content>
        Olet kirjautunut ulos.
    </mat-card-content>
    <mat-card-footer align="end">
        <a href="/" mat-button>Kirjaudu sisään</a>
    </mat-card-footer>
</mat-card>
