@if (menuSection();as section) {
    <app-main-menu [section]="section"/>
}

<router-outlet
    #routerOutlet="outlet"
    (activate)="routeActivated(routerOutlet.activatedRoute)"
    (deactivate)="routeDeactivated()"/>

@if (error();as e) {
    <div class="m-8">
        <h2>Odottamaton virhe</h2>

        <p>
            <a [href]="e.url">Yritä uudestaan</a> tai <a href="/">palaa etusivulle</a>. Jos ongelma toistuu, ota yhteys ylläpitoon.
        </p>

        @if (showErrorDetails) {
            <div class="text-gray-200 bg-black mt-4 p-2 font-mono whitespace-pre-wrap">{{ e.error | json }}</div>
        }
    </div>
}

@if (loading()) {
    <mat-spinner class="default-spinner"/>
}
