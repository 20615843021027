@if (link.routerLink) {
    <a class="whitespace-nowrap"
       [ngClass]="textClass"
       [routerLink]="link.routerLink"
       [queryParams]="link.routerLinkQueryParams"
       (click)="navigateTo($event, link)"
    >{{ link.title }}</a>
} @else {
    <a class="whitespace-nowrap"
       [ngClass]="textClass"
       [href]="link.url"
       [attr.target]="link.target"
       (click)="navigateTo($event, link)"
    >{{ link.title }}</a>
}
