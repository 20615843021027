import { buildAuthenticatedUrl, provideStompConfiguration } from "common";
import { Provider } from "@angular/core";

export function provideFrontendStompConfiguration(): Provider {
    const url = ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + "/ws";
    return provideStompConfiguration({
        brokerURL: url,
        heartbeatIncoming: 0,
        heartbeatOutgoing: 20000,
        reconnectDelay: 5000,
        beforeConnect: client => {
            client.configure({
                brokerURL: buildAuthenticatedUrl(url)
            });
        }

        // debug(msg: string): void {
        //     console.log(new Date(), msg);
        // }
    });
}
