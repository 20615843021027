import { Injectable, signal, Signal } from "@angular/core";
import { merge, timer } from "rxjs";
import { MainMenuEndpoint, MainMenuModel } from "apina-frontend";
import { RxStompService } from "common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

// Timed reloads are not strictly necessary because of the topic, but's lets do them anyway
const RELOAD_MILLIS = 15 * 60 * 1000;

/**
 * Contains the data for main menu so that it need not be refetched every time the component
 * is recreated.
 */
@Injectable({providedIn: "root"})
export class MainMenuService {

    private readonly _latestData = signal<MainMenuModel | null>(null);
    readonly latestData: Signal<MainMenuModel | null> = this._latestData.asReadonly();

    constructor(
        private readonly mainMenuEndpoint: MainMenuEndpoint,
        rxStompService: RxStompService,
    ) {
        const updates$ = merge(
            timer(0, RELOAD_MILLIS),
            rxStompService.watch("/topic/main-menu-data")
        );
        updates$.pipe(takeUntilDestroyed()).subscribe(() => this.reload());
    }

    reload(): void {
        this.mainMenuEndpoint.loadMainMenuData().subscribe(data => this._latestData.set(data));
    }
}
